var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[(_vm.rol != 'root' && _vm.rol != 'admin' && _vm.rol != 'admin-empresa')?_c('v-container',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"80vh","width":"80vw"}},[_c('v-card',{staticStyle:{"border-radius":"20px"},attrs:{"max-width":"500"}},[_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":'/static/icon/peligro.svg'}})])]),_c('v-card-text',{staticClass:"titleModal text-center"},[_vm._v(" Esta vista no esta disponible para este rol de usuario. ")]),_c('v-card-actions',{staticClass:"d-flex align-center justify-center card_accion"},[_c('v-btn',{staticClass:"btnGuardar",on:{"click":function($event){return _vm.redireccionar()}}},[_vm._v(" Ok ")])],1)],1)],1):_c('v-container',{attrs:{"xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('data-table',{ref:"tablaAcciones",attrs:{"tableName":'Tipos de Acciones Disciplinarias',"url":_vm.url,"columns":_vm.columns,"filters":_vm.filters,"perPage":[10, 25, 50, 100],"showAdvancedFilters":true},on:{"setFilters":_vm.setFilters,"loadModalData":_vm.loadModalData},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',{staticClass:"tblPrincipal"},[_vm._v(_vm._s(item.nombre))])]),_c('td',[_c('div',{staticClass:"tblPrincipal"},[_vm._v(_vm._s(item.descripcion))])]),_c('td',[(item.cliente_id != null)?_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal('update', item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(_vm.permisos.delete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()],1):_vm._e()])])}),0)}}])},[_c('template',{slot:"actionButtonsLeft"},[(_vm.permisos.create)?_c('div',{staticClass:"botonAgregar"},[_c('v-btn',{staticClass:"btnAdd",attrs:{"rounded":"","color":"#004BAF","right":""},on:{"click":function($event){return _vm.abrirModal('add')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("add")]),_vm._v(" Agregar "),_c('div',{staticClass:"mr-4"})],1)],1):_vm._e()]),_c('template',{slot:"filters"},[(_vm.rol == 'root')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.clientes,"item-text":"nombre","item-value":"id","label":"Cliente","persistent-hint":"","no-data-text":"Datos no disponibles"},model:{value:(_vm.cliente_value),callback:function ($$v) {_vm.cliente_value=$$v},expression:"cliente_value"}})],1):_vm._e(),(_vm.rol == 'root' || _vm.rol == 'admin')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":_vm.rol == 'admin' ? '12' : '6',"md":_vm.rol == 'admin' ? '12' : '6'}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.empresas,"item-text":"nombre","item-value":"id","label":"Empresa","persistent-hint":"","no-data-text":"Datos no disponibles"},model:{value:(_vm.empresa_value),callback:function ($$v) {_vm.empresa_value=$$v},expression:"empresa_value"}})],1):_vm._e()],1)],2)],1)],1),[_c('v-dialog',{staticClass:"borde-card",attrs:{"persistent":"","max-width":"750px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"borde-card"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('h2',{staticClass:"titleModal"},[_vm._v(_vm._s(_vm.tituloModal))])])]),_c('v-card-text',{staticClass:"card_texto"},[_c('v-container',{attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-divider',{staticClass:"divider"}),_c('v-row',[(_vm.rol == 'root')?[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Cliente","items":_vm.clientes,"item-text":"nombre","item-value":"id","persistent-hint":"","required":"","error-messages":errors},model:{value:(_vm.cliente_value),callback:function ($$v) {_vm.cliente_value=$$v},expression:"cliente_value"}})]}}],null,true)})],1)]:_vm._e(),(_vm.rol == 'root' || _vm.rol == 'admin')?[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Empresa","items":_vm.empresas,"item-text":"nombre","item-value":"id","persistent-hint":"","required":"","error-messages":errors},model:{value:(_vm.empresa_value),callback:function ($$v) {_vm.empresa_value=$$v},expression:"empresa_value"}})]}}],null,true)})],1)]:_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Clave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Clave","error-messages":errors,"required":""},model:{value:(_vm.accion_disciplinaria.clave),callback:function ($$v) {_vm.$set(_vm.accion_disciplinaria, "clave", $$v)},expression:"accion_disciplinaria.clave"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":(_vm.rol == 'admin')? 12 : 6,"md":(_vm.rol == 'admin')? 12 : 6}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre","error-messages":errors,"required":""},model:{value:(_vm.accion_disciplinaria.nombre),callback:function ($$v) {_vm.$set(_vm.accion_disciplinaria, "nombre", $$v)},expression:"accion_disciplinaria.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"12"}},[_c('v-textarea',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Descripción","rows":4},model:{value:(_vm.accion_disciplinaria.descripcion),callback:function ($$v) {_vm.$set(_vm.accion_disciplinaria, "descripcion", $$v)},expression:"accion_disciplinaria.descripcion"}})],1)],2)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end card_accion"},[_c('v-btn',{staticClass:"btnCerrar",attrs:{"disabled":_vm.isSaving,"elevation":0},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v(" Cancelar ")]),((_vm.permisos.update) || (_vm.permisos.create && _vm.permisos.update == false && _vm.accion == 'add'))?_c('v-btn',{staticClass:"btnGuardar",attrs:{"disabled":invalid,"loading":_vm.isSaving,"elevation":0},on:{"click":function($event){return _vm.guardar()}}},[_vm._v(" Guardar ")]):_vm._e()],1),(_vm.permisos.update == false && _vm.accion == 'update')?_c('div',{staticClass:"px-15 pb-10"},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" El usuario no tiene permiso para editar el registro. ")])],1):_vm._e()]}}])})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }