<template>
    <div id="pageTable">
        <v-container
            class="d-flex align-center justify-center"
            style="height: 80vh; width: 80vw"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
            <v-card class="" style="border-radius: 20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal" />
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn class="btnGuardar" @click="redireccionar()"> Ok </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaAcciones"
                        :tableName="'Tipos de Acciones Disciplinarias'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :perPage="[10, 25, 50, 100]"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon> Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>

                        <template slot="filters">
                            <v-col
                                v-if="rol == 'root'"
                                cols="12"
                                xs="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <v-col
                                v-if="rol == 'root' || rol == 'admin'"
                                cols="12"
                                xs="12"
                                :sm="rol == 'admin' ? '12' : '6'"
                                :md="rol == 'admin' ? '12' : '6'"
                                class="py-0"
                            >
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                           
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.descripcion }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones" v-if="item.cliente_id != null">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    @click="abrirModal('update', item)"
                                                    small
                                                >
                                                    <v-icon
                                                        class="tamIconoBoton invertirColorBotones"
                                                        >edit</v-icon
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    @click="eliminar(item)"
                                                    small
                                                >
                                                    <v-icon
                                                        class="tamIconoBoton invertirColorBotones"
                                                        >delete</v-icon
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <!--MODAL-->
            <template>
                <v-dialog v-model="dialog" persistent max-width="750px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template v-if="rol == 'root'">
                                            <v-col xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider
                                                    v-slot="{ errors }"
                                                    name="Cliente"
                                                    rules="required"
                                                >
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required
                                                        :error-messages="errors"
                                                    ></v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <template v-if="rol == 'root' || rol == 'admin'">
                                            <v-col xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider
                                                    v-slot="{ errors }"
                                                    name="Empresa"
                                                    rules="required"
                                                >
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        class="pa-0 ma-0"
                                                        required
                                                        :error-messages="errors"
                                                    ></v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Clave"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    outlined
                                                    label="Clave"
                                                    class="pa-0 ma-0"
                                                    v-model="accion_disciplinaria.clave"
                                                    :error-messages="errors"
                                                    required
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col xs="12" :sm="(rol == 'admin')? 12 : 6" :md="(rol == 'admin')? 12 : 6" class="py-0">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Nombre"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    outlined
                                                    label="Nombre"
                                                    class="pa-0 ma-0"
                                                    v-model="accion_disciplinaria.nombre"
                                                    :error-messages="errors"
                                                    required
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12" class="py-0">
                                            <v-textarea
                                                outlined
                                                label="Descripción"
                                                class="pa-0 ma-0"
                                                :rows="4"
                                                v-model="accion_disciplinaria.descripcion"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <v-btn
                                    class="btnCerrar"
                                    @click="cerrarModal()"
                                    :disabled="isSaving"
                                    :elevation="0"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()"
                                    :disabled="invalid"
                                    :loading="isSaving"
                                    :elevation="0"
                                    v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                            <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    
                                >
                                    El usuario no tiene permiso para editar el registro.
                                </v-alert>
                            </div>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
    import Datatable from "@/components/datatable/Datatable.vue";
    import Notify from "@/plugins/notify";
    import AccionesApi from "@/api/tiposAccionesDisc";
    import clientesApi from "@/api/clientes";
    import empresasApi from "@/api/empresas";

    export default {
        components: {
            "data-table": Datatable,
        },

        data() {
            return {
                datosLogin: null,
                rol: null,
                url: "catalogo/llamado_atencion_tipos/find",
                columns: [
                    {
                        label: "Nombre",
                        name: "nombre",
                        filterable: false,
                    },
                    {
                        label: "Descripción",
                        name: "descripcion",
                        filterable: false,
                    },
                    {
                        label: "Opciones",
                        name: "opciones",
                        align: "center",
                        filterable: false,
                    },
                ],
                filters: {
                    activo: true,
                    paginate: true,
                    cliente_id: null,
                    empresa_id: null,
                    nombre: "",
                },
                isLoading: true,
                accion_disciplinaria: {
                    cliente_id: null,
                    empresa_id: null,
                    clave: "",
                    nombre: "",
                    descripcion: "",
                },
                tituloModal: "",
                accion: "",
                isLoading: false,
                dialog: false,
                isSaving: false,
                cliente_value: null,
                empresa_value: null,
                clientes: [],
                empresas: [],
                filterNombre: "",
                permisos :{
                    create: false,
                    update: false,
                    delete: false,
                }
            };
        },
        watch: {
            cliente_value: function (val) {
                this.getEmpresas();
            },
        },
        methods: {
            listar() {
                if (this.rol == "root") {
                    this.getClientes();
                } else if (this.rol == "admin") {
                    this.filters.cliente_id = this.datosLogin.cliente_id;
                    this.cliente_value = this.datosLogin.cliente_id;
                } else if (this.rol == "admin-empresa") {
                    this.filters.cliente_id = this.datosLogin.cliente_id;
                    this.filters.empresa_id = this.datosLogin.empresa_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
            },
            async getClientes() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    order_by: "nombre",
                };

                await clientesApi
                    .getClientes(parametros)
                    .then((response) => {
                        this.clientes = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
                    });
            },
            getEmpresas() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    order_by: "nombre",
                    cliente_id: this.cliente_value,
                };
                empresasApi
                    .getEmpresas(parametros)
                    .then((response) => {
                        this.empresas = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                    });
            },
            abrirModal(accion, item = null) {
                this.accion = accion;
                this.dialog = true;

                if (accion == "add") {
                    this.tituloModal = "Nueva Acción Disciplinaria";
                    if (this.rol === "admin") {
                        this.cliente_value = this.datosLogin.cliente_id;
                    } else if (this.rol === "admin-empresa") {
                        this.cliente_value = this.datosLogin.cliente_id;
                        this.empresa_value = this.datosLogin.empresa_id;
                    }
                } else if (accion == "update") {
                    this.tituloModal = "Actualizar Acción Disciplinaria";
                    this.cliente_value = item.cliente_id;
                    this.empresa_value = item.empresa_id;
                    this.accion_disciplinaria.nombre = item.nombre;
                    this.accion_disciplinaria.descripcion = item.descripcion;
                    this.accion_disciplinaria.clave = item.clave;
                    this.accion_disciplinaria.id = item.id;
                }
            },
            cerrarModal() {
                this.dialog = false;
                this.resetValues();
            },
            guardar() {
                this.$refs.form.validate().then((success) => {
                    this.isSaving = true;
                    if (!success) {
                        this.isSaving = false;
                        return;
                    }
                    this.accion_disciplinaria.cliente_id = this.cliente_value;
                    this.accion_disciplinaria.empresa_id = this.empresa_value;

                    if (this.accion === "add") {
                        AccionesApi.addTipoAccionDisciplinaria(this.accion_disciplinaria)
                            .then((resp) => {
                                Notify.Success(
                                    "Operación exitosa",
                                    "La Acción Disciplinaria se guardó satisfactoriamente"
                                );
                                this.$refs.tablaAcciones.getData();
                                this.isSaving = false;
                                this.cerrarModal();
                                this.$nextTick(() => {
                                    this.$refs.form.reset();
                                });
                            })
                            .catch((err) => {
                                this.isSaving = false;
                                if (typeof err.response.data.error === "string") {
                                    Notify.ErrorToast(err.response.data.error);
                                } else {
                                    let error = Object.values(err.response.data.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda += `* ` + error[i] + `\n`;
                                    }
                                    Notify.ErrorToast(leyenda);
                                }
                            });
                    } else if (this.accion === "update") {
                        AccionesApi.updateTipoAccionDisciplinaria(this.accion_disciplinaria)
                            .then((resp) => {
                                Notify.Success(
                                    "Operación exitosa",
                                    "La Acción Disciplinaria se actualizó satisfactoriamente"
                                );
                                this.$refs.tablaAcciones.getData();
                                this.isSaving = false;
                                this.cerrarModal();
                                this.$nextTick(() => {
                                    this.$refs.form.reset();
                                });
                            })
                            .catch((err) => {
                                this.isSaving = false;
                                if (typeof err.response.data.error === "string") {
                                    Notify.ErrorToast(err.response.data.error);
                                } else {
                                    let error = Object.values(err.response.data.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda += `* ` + error[i] + `\n`;
                                    }
                                    Notify.ErrorToast(leyenda);
                                }
                            });
                    }
                });
            },
            eliminar(item) {
                Notify.Alert(
                    "ADVERTENCIA",
                    "¿Estás seguro que deseas eliminar la acción disciplinaria " +
                        item.nombre +
                        "?",
                    "Eliminar",
                    () => {
                        AccionesApi.deleteTipoAccionDisciplinaria(item.id)
                            .then((resp) => {
                                Notify.Success(
                                    "Operación Eliminada",
                                    "La acción disciplinaria ha sido eliminada correctamente"
                                );
                                this.$refs.tablaAcciones.getData();
                            })
                            .catch((err) => {
                                if (typeof err.response.data.error === "string") {
                                    Notify.ErrorToast(err.response.data.error);
                                } else {
                                    let error = Object.values(err.response.data.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda += `* ` + error[i] + `\n`;
                                    }

                                    Notify.ErrorToast(leyenda);
                                }
                            });
                    }
                );
            },
            async loadModalData() {
                await this.resetValues();
                if (this.rol == "root") {
                    await this.getClientes();
                } else if (this.rol == "admin") {
                    this.cliente_value = this.datosLogin.cliente_id;
                } else if (this.rol == "admin-empresa") {
                    this.empresa_value = this.datosLogin.empresa_id;
                }
            },
            async setFilters() {
                let cliente = this.cliente_value;
                let empresa = this.empresa_value;
                let nombre = this.filterNombre;

                if (this.rol == "admin") {
                    cliente = this.datosLogin.cliente_id;
                } else if (this.rol == "admin-empresa") {
                    cliente = this.datosLogin.cliente_id;
                    empresa = this.datosLogin.empresa_id;
                }

                this.filters.cliente_id = cliente;
                this.filters.empresa_id = empresa;
                this.filters.nombre = nombre;

                this.$refs.tablaAcciones.getData();
                await this.resetValues();
            },
            async resetValues() {
                this.accion_disciplinaria = {
                    cliente_id: null,
                    empresa_id: null,
                    clave: "",
                    nombre: "",
                    descripcion: "",
                };
                this.tituloModal = "";
                this.cliente_value = null;
                this.empresa_value = null;
                this.filterNombre = ""
            },
            redireccionar() {
                this.$router.push("/dashboard");
            },
            permisosVista(){
                let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
                this.permisos.create = permisosStorage.includes("catalogo.llamado_atencion_tipos.store")
                this.permisos.update = permisosStorage.includes("catalogo.llamado_atencion_tipos.update")
                this.permisos.delete = permisosStorage.includes("catalogo.llamado_atencion_tipos.delete")
            },
        },
        created() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.listar();
            this.permisosVista();
        },
    };
</script>

<style scoped></style>
